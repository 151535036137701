<template>
  <q-form ref="editForm">
    <!-- 교육과정 정보 -->
     <c-card title="LBL0002876" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <!-- 저장 -->
          <c-btn
            v-if="editable && !disabled"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="educationInfo"
            :mappingType="saveType"
            label="LBLSAVE"
            icon="save"
            @beforeAction="saveEducation"
            @btnCallback="saveCallback" 
          />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <!-- 교육과정 -->
          <c-text
            :afterIcon="disabled ? null :[
              { name: 'search', click: true, callbackName: 'searchEduMaster'},
              { name: 'close', click: true, callbackName: 'removeEduMaster' }
            ]"
            :required="true" 
            :readonly="true"
            :close="true"
            :editable="editable"
            label="LBL0002930"
            name="educationCourseName"
            @searchEduMaster="searchEduMaster"
            @removeEduMaster="removeEduMaster"
            v-model="educationInfo.educationCourseName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 교육종류1 -->
          <c-select
            :editable="editable"
            :disabled="true"
            type="edit"
            codeGroupCd="EDU_KIND_FST_CD"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdLarge"
            label="LBL0002933"
            v-model="educationInfo.educationKindCdLarge"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 교육종류2 -->
          <c-select
            :disabled="true"
            :editable="editable"
            type="edit"
            codeGroupCd="EDU_KIND_SEC_CD"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdSmall"
            label="LBL0002934"
            v-model="educationInfo.educationKindCdSmall"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
          <!-- 법정교육여부 -->
          <c-checkbox
            :disabled="true"
            :isFlag="true"
            :editable="editable"
            type="edit"
            label="LBL0002800"
            name="legalEducationFlag"
            v-model="educationInfo.legalEducationFlag">
          </c-checkbox>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
            <!-- v-show="educationInfo.legalEducationFlag==='Y'?true:false" -->
          <!-- 법정교육주기 -->
          <c-select
            :editable="false"
            type="edit"
            codeGroupCd="EDU_CYCLE_CD"
            itemText="codeName"
            itemValue="code"
            name="educationCycle"
            label="LBL0002802"
            v-model="educationInfo.educationCycle"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 원 -->
          <!-- 예상 교육비 -->
          <c-text
            :suffix="$label('LBL0002813')"
            type="number"
            :editable="editable"
            :disabled="true"
            label="LBL0002803"
            name="estimatedEducationExpenses"
            v-model="educationInfo.estimatedEducationExpenses">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <!-- 학습목적 -->
          <c-textarea
            :disabled="true"
            :editable="editable"
            :rows="1"
            label="LBL0002937"
            name="educationPurpose"
            v-model="educationInfo.educationPurpose">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <!-- 관련 법규 -->
          <c-multi-select
            :disabled="true"
            :editable="editable"
            :isArray="false"
            codeGroupCd="REGULATION_BILL_CD"
            itemText="codeName"
            itemValue="code"
            label="LBL0002804"
            name="relationLaws"
            maxValues="3"
            v-model="educationInfo.relationLaws">
          </c-multi-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <!-- 주요 대상자 -->
          <c-text
            :disabled="true"
            :editable="editable"
            label="LBL0002814"
            name="mainTargetAudience"
            v-model="educationInfo.mainTargetAudience">
          </c-text>
        </div>
      </template>
    </c-card>
    <!-- 교육계획 기본정보 -->
    <c-card title="LBL0002877" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <!-- 교육명 -->
          <c-text
            required
            :disabled="disabled"
            :editable="editable"
            label="LBL0002929"
            name="educationName"
            v-model="educationInfo.educationName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 주관부서 -->
          <c-dept
            :editable="editable"
            :required="true" 
            :disabled="disabled"
            :data="educationInfo"
            label="LBL0002854"
            name="departmentDeptCd"
            v-model="educationInfo.departmentDeptCd">
          </c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 교육구분 -->
          <c-select
            :disabled="disabled"
            :editable="editable"
            :required="true" 
            type="edit"
            codeGroupCd="EDU_CLASS_CD"
            itemText="codeName"
            itemValue="code"
            name="educationTypeCd"
            label="LBL0002935"
            v-model="educationInfo.educationTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
          <!-- 기간,일 -->
          <!-- true: 기간, false: 일 -->
          <c-checkbox
            :disabled="disabled"
            :editable="editable"
            :isFlag="true"
            label="LBL0002885"
            :trueLabel="$label('LBLPERIOD')"
            :falseLabel="$label('LBL0002886')"
            name="educationRangeFlag"
            v-model="educationInfo.educationRangeFlag"
            @datachange="datachange"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 연간교육계획이 결재중인 경우 교육일시 수정 불가 -->
          <!-- 교육기간 -->
          <c-datepicker
            v-show="isDate"
            :required="isDate"
            :disabled="disabled || yearEduAppr"
            :editable="editable"
            label="LBL0002829"
            :range="true"
            type="date"
            name="educationPeriod"
            v-model="educationPeriod">
          </c-datepicker>
          <!-- 교육일 -->
          <c-datepicker
            v-show="!isDate"
            :required="!isDate"
            :disabled="disabled || yearEduAppr"
            :editable="editable"
            label="LBL0002931"
            type="date"
            name="educationDate"
            v-model="educationDate">
          </c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant 
            :required="true" 
            :editable="editable" 
            :disabled="disabled"
            type="edit" 
            name="plantCd" 
            v-model="educationInfo.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 교육시간 -->
          <c-datepicker
            required
            :disabled="disabled"
            :editable="editable"
            label="LBL0002932"
            type="time"
            :range="true"
            :minuteStep="10"
            name="educationTime"
            v-model="educationTime">
          </c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 교육장소 -->
          <c-text
            :disabled="disabled"
            :editable="editable"
            label="LBL0002839"
            name="educationLocation"
            v-model="educationInfo.educationLocation">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 교육방법 -->
          <c-select
            :disabled="disabled"
            :editable="editable"
            type="edit"
            codeGroupCd="EDU_METHOD_CD"
            itemText="codeName"
            itemValue="code"
            name="educationMethodCd"
            label="LBL0002936"
            v-model="educationInfo.educationMethodCd"
            @datachange="educationMethodChange"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <!-- 교육용 동영상 -->
          <c-select
            :disabled="disabled || educationInfo.educationMethodCd !== 'EMC999999'"
            :editable="editable"
            :comboItems="educationVodItems"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="vod"
            label="LBL0002879"
            v-model="educationInfo.vod"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-risk-assess
            :editable="editable"
            :disabled="disabled"
            name="ramRiskAssessmentPlanId"
            v-model="educationInfo.ramRiskAssessmentPlanId">
          </c-risk-assess>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <!-- 자체감사 -->
          <c-text
            :afterIcon="disabled ? null :[
              { name: 'search', click: true, callbackName: 'searchCompliance'},
              { name: 'close', click: true, callbackName: 'removeCompliance' }
            ]"
            :close="false"
            :readonly="true"
            :editable="editable"
            :detail="true"
            label="LBL0002880"
            name="selfInspectionTitle"
            @searchCompliance="searchCompliance"
            @detailPop="detailCompliance"
            @removeCompliance="removeCompliance"
            v-model="educationInfo.selfInspectionTitle">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
          <!-- 문제풀이여부 -->
          <c-checkbox
            :disabled="disabled"
            :editable="editable"
            :isFlag="true"
            label="LBL0002815"
            name="eduQuestionFlag"
            v-model="educationInfo.eduQuestionFlag">
          </c-checkbox>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 문제풀이SET -->
          <c-edu-quiz 
            :required="educationInfo.eduQuestionFlag == 'Y'"
            :disabled="disabled || educationInfo.eduQuestionFlag !== 'Y'"
            :editable="editable"
            label="LBL0002816"
            name="eduQuestionMstId"
            v-model="educationInfo.eduQuestionMstId"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
          <!-- 통과기준점수 -->
          <c-text
            :required="educationInfo.eduQuestionFlag == 'Y'"
            :disabled="disabled || educationInfo.eduQuestionFlag !== 'Y'"
            :editable="editable"
            type="number"
            label="LBL0002817"
            name="eduQuestionPassScore"
            v-model="educationInfo.eduQuestionPassScore">
          </c-text>
        </div>
      </template>
    </c-card>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <!-- 교육내용 -->
      <!-- 교육내용이 없습니다. -->
      <c-table
        ref="table"
        title="LBL0002820"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="educationInfo.eduSubjectList"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :hideBottom="true"
        :noDataLabel="$message('MSG0000865')"
        :editable="editable && !disabled"
        rowKey="subjectNo"
        selection="multiple"
        @table-data-change="tableDataChange"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <!-- 추가 -->
            <c-btn v-if="editable && !disabled" label="LBLADD" :showLoading="false"  icon="add" @btnClicked="addItem" />
            <!-- 제외 -->
            <c-btn v-if="editable && !disabled && educationInfo.eduSubjectList.length > 0" label="LBLEXCEPT" :showLoading="false" icon="remove" @btnClicked="removeItem" />
          </q-btn-group>
        </template>
        <template v-if="educationInfo.eduSubjectList && educationInfo.eduSubjectList.length>0"  slot="suffixTitle">
          <font class="text-negative" style="font-size:0.8em;font-weight:300;">
            <!-- ※ 총 교육시간 :  -->
            {{$label('LBL0002881')}}
          </font>
          <q-chip
            outline square 
            color="primary"
            text-color="white"
            class="q-ma-none customchipdept">
            <template>
              {{ educationInfo.sumEducationTime }}
            </template>
          </q-chip>
        </template>
      </c-table>
    </div>
  <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>
<script>

import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'education-plan-info',

  props: {
    param: {
      type: Object,
      default: () => ({
        eduEducationId: '',
        planUpdateBtnData: {
          title: 'LBLPLAN',
          flag: false,
          research: '',
          planResearch: '',
        },
      }),
    },
    educationDate: {
      type: String,
      default: '',
    },
    educationInfo: {
      type: Object,
      default: () => ({
        eduEducationId: '',
        eduEducationYearPlanId: '',
        educationName: '',
        eduCourseId: '',
        plantCd: '',
        educationYear: '',
        educationCourseName: '',
        educationKindCdLarge: null,
        educationKindCdSmall: null,
        educationTypeCd: null,
        departmentDeptCd: '',
        legalEducationFlag: null,
        educationTime: '',
        educationCycle: null,
        educationPurpose: '',
        relationLaws: '',
        mainTargetAudience: '',
        educationMonth: null,
        educationLocation: '',
        estimatedEducationExpenses: '',
        executionBudget: '',
        educationStartDate: '',
        educationEndDate: '',
        educationStartTime: '',
        educationEndTime: '',
        instructorInformation: '',
        sopMocId: '',
        educationMethodCd: null,
        educationOverview: '',
        evaluationEvalCd: '',
        documentStatusCd: '',
        qrCodeRoute: '',
        educationRangeFlag: 'Y',
        eduQuestionFlag: 'N',
        eduQuestionMstId: '',
        eduQuestionPassScore: 0,
        checkAppr: '',
        regUserId: '',
        chgUserId: '',  

        vod: null,

        // 위험성평가 결과 항목 추가
        ramRiskAssessmentPlanId: '',

        // 자체감사 항목 추가
        selfInspectionResultId: '',
        selfInspectionStatusCd: '',
        sysRevision: '',
        selfInspectionTitle: '',

        eduSubjectList: [], // 과정별 교육과목
        deleteEduSubjectList: [],
      }),
    },
    saveCall: {
      type: Object,
      default: () => ({
        saveCallData: '',
      }),
    },
  },
  data() {
    return {
      educationVodItems: [],
      editable: true,
      flagItems: [
        { code: 'Y', codeName: '해당' },
        { code: 'N', codeName: '해당없음' },
      ],
      grid: {
        columns: [
          {
            name: 'subjectName',
            field: 'subjectName',
            // 과목 및 세부내용
            label: 'LBL0002818',
            align: 'left',
            style: 'width:50%',
            type: 'text',
            sortable: false,
          },
          // {
          //   name: 'subjectDetailName',
          //   field: 'subjectDetailName',
          //   label: '세부내용',
          //   style: 'width:100px',
          //   align: 'left',
          //   type: 'text',
          //   sortable: false,
          // },
          {
            name: 'educationTime',
            field: 'educationTime',
            // 교육시간
            label: 'LBL0002932',
            type: 'datetime',
            style: 'width:20%',
            align: 'center',
            sortable: false
          },
          {
            name: 'instructorName',
            field: 'instructorName',
            // 강사명
            type: 'text',
            label: 'LBL0002819',
            style: 'width:30%',
            align: 'left',
            sortable: false
          },
        ],
        height: '300px'
      },
      title: '',
      
      selectDate: '',
      isOpen: false,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      saveUrl: 'transactionConfig.sop.edu.plan.insert.url',
      saveType: 'POST',
      isSave: false,
      getUrl: '',
      insertUrl: '',
      deleteUrl: '',
      updateUrl: '',
      updateMode: false,
      newDate: ['', ''],
      newTime: ['', ''],
    };
  },
  computed: {
    disabled() {
      return (Boolean(this.educationInfo.documentStatusCd) && this.educationInfo.documentStatusCd !== 'ESC000001') && !this.param.planUpdateBtnData.flag
    },
    yearEduAppr() {
      // 연간교육계획이 결재중인지 여부 체크
      return this.educationInfo.checkAppr === 'ASC0000001'
    },
    isDate() {
      return this.educationInfo.educationRangeFlag === 'Y'
    },
    educationPeriod: {
      get() {
        if (this.educationInfo.educationStartDate && this.educationInfo.educationEndDate) {
          return [this.educationInfo.educationStartDate, this.educationInfo.educationEndDate]
        } else {
          return this.newDate;
        }
      },
      set(newDate) {
        if (newDate && newDate.length > 0) {
          this.educationPeriod[0] = newDate[0]
          this.educationPeriod[1] = newDate[1]
        } 
        this.newDate = newDate;
      }
    },
    educationTime: {
      get() {
        if (this.educationInfo.educationStartTime && this.educationInfo.educationEndTime) {
          return [this.educationInfo.educationStartTime, this.educationInfo.educationEndTime]
        } else {
          return this.newTime;
        }
      },
      set(newTime) {
        if (newTime && newTime.length > 0) {
          this.educationTime[0] = newTime[0]
          this.educationTime[1] = newTime[1]
        }
        this.newTime = newTime;
      }
    }
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      this.insertUrl = transactionConfig.sop.edu.plan.insert.url;
      this.updateUrl = transactionConfig.sop.edu.plan.update.url;
      this.eduVods();
    },
    eduVods() {
        this.educationVodItems = [];
        this.$http.url = selectConfig.com.upload.fileList.url;
        this.$http.type = 'GET';
        this.$http.param = {
          taskClassCd: 'EDU_CURRICULUM',
          taskKey: this.educationInfo.eduCourseId,
        };
        this.$http.request((_result2) => {
          this.$_.forEach(_result2.data, item1 => {
            if (item1.contentType == 'video/mp4') {
              this.educationVodItems.push({
                code: item1.sysAttachFileId,
                codeName: item1.oriFileNm,
              })
            }
          })
          if (this.educationInfo.eduEducationId) {
            this.$http.url = selectConfig.com.upload.fileList.url;
            this.$http.type = 'GET';
            this.$http.param = {
              taskClassCd: 'EDU_PLAN',
              taskKey: this.educationInfo.eduEducationId,
            };
            this.$http.request((_result) => {
              this.$_.forEach(_result.data, item2 => {
                if (item2.contentType == 'video/mp4') {
                  this.educationVodItems.push({
                    code: item2.sysAttachFileId,
                    codeName: item2.oriFileNm,
                  })
                }
              })
            },);
          }
        },);
    },
    educationMethodChange() {
      if (this.educationInfo.educationMethodCd !== 'EMC999999') {
        this.educationInfo.vod = null;
      } else {
        this.eduVods();
      }
    },
    tableDataChange(props, col) {
      if (col.name === 'educationTime') {
        let hours = 0;
        let minutes = 0;
        this.$_.forEach(this.educationInfo.eduSubjectList, _item => {
          if (_item.educationTime) {
            let timeArray = _item.educationTime.split(":");
            hours += Number(timeArray[0])
            minutes +=Number(timeArray[1])
          }
        })
        if (minutes >= 60) {
          hours += Number(parseInt(minutes / 60))
          minutes = Number(minutes % 60)
        }
        this.educationInfo.sumEducationTime = hours + '시간 ' + minutes + '분 ';
      }
    },
    saveEducation() {
      if (this.param.eduEducationId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.educationInfo.regUserId = this.$store.getters.user.userId
              this.educationInfo.chgUserId = this.$store.getters.user.userId
              
              if (this.educationInfo.educationRangeFlag === 'Y') {
                if (this.educationPeriod && this.educationPeriod.length > 0) {
                  this.educationInfo.educationStartDate = this.educationPeriod[0];
                  this.educationInfo.educationEndDate = this.educationPeriod[1];
                }
              } else {
                this.educationInfo.educationStartDate = this.educationDate;
                this.educationInfo.educationEndDate = this.educationDate;
              }
              if (this.educationTime && this.educationTime.length > 0) {
                this.educationInfo.educationStartTime = this.educationTime[0];
                this.educationInfo.educationEndTime = this.educationTime[1];
              }
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.param.eduEducationId = result.data;
      this.saveCall.saveCallData = uid();
      this.$emit('setData', result.data)
    },
    addItem() {
      this.educationInfo.eduSubjectList.push({
        eduEducationId: this.param.eduEducationId,
        eduCourseId: this.eduCourseId,
        subjectNo: uid(),
        subjectName: '', // 교육명
        subjectDetailName: '', // 세부내용
        educationTime: '', // 교육시간
        instructorName: '', // 강사명
        editFlag: 'C',
      })
    },
    removeItem() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.educationInfo.eduSubjectList = this.$_.reject(this.educationInfo.eduSubjectList, item)
        })
        this.$_.forEach(selectData, item => {
          if (!this.educationInfo.deleteEduSubjectList) {
            this.educationInfo.deleteEduSubjectList = []
          }
          if (this.$_.findIndex(this.educationInfo.deleteEduSubjectList, { subjectNo: item.subjectNo }) === -1
            && item.editFlag !== 'C') {
              this.educationInfo.deleteEduSubjectList.push(item)
          }
          this.educationInfo.eduSubjectList = this.$_.reject(this.educationInfo.eduSubjectList, item)
        })
      }
    },
    searchEduMaster() {
      this.popupOptions.title = 'LBL0002860'; // 교육과정 검색
      this.popupOptions.param = {
        type: 'single',
        plantCd: this.educationInfo.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/edu/cc/educationCoursePop.vue'}`);
      this.popupOptions.width = '95%';
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEduCoursePopup;
    },
    closeEduCoursePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data.selectData && data.selectData.length > 0) {
        this.$http.url = this.$format(selectConfig.sop.edu.course.get.url, data.selectData[0].eduCourseId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.forEach(this.educationInfo.eduSubjectList, item => {
            if (!this.educationInfo.deleteEduSubjectList) {
              this.educationInfo.deleteEduSubjectList = []
            }
            if (this.$_.findIndex(this.educationInfo.deleteEduSubjectList, { subjectNo: item.subjectNo }) === -1
              && item.editFlag !== 'C') {
                this.educationInfo.deleteEduSubjectList.push(item)
            }
            this.educationInfo.eduSubjectList = this.$_.reject(this.educationInfo.eduSubjectList, item)
          })
          // 빈값들 filter
          this.educationInfo.educationCourseName = _result.data.educationCourseName, // 교육과정명
          // 교육과정 마스터 데이터 
          this.educationInfo.eduCourseId = _result.data.eduCourseId, // 교육과정 key
          this.educationInfo.educationCycle = _result.data.educationCycle, // 교육주기
          this.educationInfo.educationKindCdLarge = _result.data.educationKindCdLarge, // 교육종류 대
          this.educationInfo.educationKindCdSmall = _result.data.educationKindCdSmall, // 교육종류 소
          this.educationInfo.educationPurpose = _result.data.educationPurpose, // 학습목적
          this.educationInfo.legalEducationFlag = _result.data.legalEducationFlag, // 법정여부
          this.educationInfo.mainTargetAudience = _result.data.mainTargetAudience, // 주요대상자
          this.educationInfo.relationLaws = _result.data.relationLaws, // 관련법규
          this.educationInfo.educationTime = _result.data.educationTime // 교육시간
          this.educationInfo.estimatedEducationExpenses = _result.data.estimatedEducationExpenses // 교육시간
          this.educationInfo.eduSubjectList = _result.data.eduCourseSubjectList;
          this.educationInfo.eduQuestionFlag = _result.data.eduQuestionFlag;
          this.educationInfo.eduQuestionMstId = _result.data.eduQuestionMstId;
          this.educationInfo.eduQuestionPassScore = _result.data.eduQuestionPassScore;
          
          this.$_.forEach(this.educationInfo.eduSubjectList, _item => {
            _item.eduEducationId = this.param.eduEducationId
            _item.editFlag = 'C'
          })
        },);
      }
    },
    linkClick() {
      this.popupOptions.title = 'LBL0002884'; // 교육과정 상세
      this.popupOptions.param = {};
      this.popupOptions.target = () => import(`${'@/pages/sop/edu/cc/educationCourseDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeLinkPopup;
    },
    closeLinkPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    removeEduMaster() {
      this.educationInfo = {
        eduEducationId: '',
        eduCourseId: '',
        plantCd: '',
        educationYear: '',
        educationCourseName: '',
        educationKindCdLarge: null,
        educationKindCdSmall: null,
        // educationTypeCd: null,
        departmentDeptCd: '',
        legalEducationFlag: null,
        educationTime: '',
        educationCycle: null,
        educationPurpose: '',
        relationLaws: '',
        mainTargetAudience: '',
        educationMonth: null,
        educationLocation: '',
        budgetPlan: '',
        executionBudget: '',
        educationStartDate: '',
        educationEndDate: '',
        instructorInformation: '',
        sopMocId: '',
        educationMethodCd: null,
        educationOverview: '',
        evaluationEvalCd: '',
        documentStatusCd: '',
        qrCodeRoute: '',

        eduSubjectList: [],
      }
    },
    searchCompliance() {
      this.popupOptions.title = 'LBL0002883'; // 자체감사 검색
      this.popupOptions.param = {
        type: 'single',
        plantCd: this.educationInfo.plantCd
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/pca/compliancePop.vue'}`);
      this.popupOptions.width = '90%';
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeCompliancePopup;
    },
    closeCompliancePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.educationInfo.selfInspectionResultId = data[0].selfInspectionResultId
        this.educationInfo.selfInspectionTitle = data[0].selfInspectionTitle
        this.educationInfo.selfInspectionStatusCd = data[0].selfInspectionStatusCd
        this.educationInfo.sysRevision = data[0].sysRevision
      }
    },
    detailCompliance() {
      if (!this.educationInfo.selfInspectionResultId) return;
      
      this.popupOptions.title = 'LBL0002882'; // 자체감사 상세 
      this.popupOptions.target = () => import(`${"@/pages/sop/pca/complianceDetail.vue"}`);
      this.popupOptions.param = {
        selfInspectionResultId: this.educationInfo.selfInspectionResultId,
        selfInspectionStatusCd: this.educationInfo.selfInspectionStatusCd,
        sysRevision: this.educationInfo.sysRevision,
      };
      this.popupOptions.width = '90%';
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeLinkPopup;
    },
    removeCompliance() {
      this.educationInfo.selfInspectionResultId = ''
      this.educationInfo.selfInspectionTitle = ''
      this.educationInfo.selfInspectionStatusCd = ''
      this.educationInfo.sysRevision = ''
    },
    datachange() {
      this.educationInfo.educationStartDate = '';
      this.educationInfo.educationEndDate = '';
      if (this.educationInfo.educationRangeFlag === 'Y') {
        this.educationDate = '';
      } else {
        this.educationPeriod = ['', ''];
      }
    }
  }
};
</script>
<style>
.canvas {
  width: 100%;
  height: 100%;
  margin: 3 auto;
  display: inline;
}
.wrap {
  display:flex;
  flex-wrap:wrap;
  padding:20px;
  height:400px;
  box-sizing:border-box;
  border-color: #aaa;
}
.facilityStyle {
  height:200px;
  width:300px;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-wrap:wrap;
}
.abc {
  width:100%;
  height:20px;
  display:inline;
}
</style>